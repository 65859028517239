@use '../utils/svg';

// -----------------------------------------------------------------------------
// Input and select field default styling
input[type='text'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='url'],
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='color'],
input[type='file'],
textarea,
select {
    @apply block flex-1 py-3 px-4 w-full bg-white border border-gray-200 transition appearance-none;

    &.input-lg {
        @apply py-4 px-5;
    }

    // Grey out when disabled or read only
    &:disabled, &[readonly] {
        @apply bg-gray-200;
    }

    &:focus {
        @apply outline-none ring ring-primary border-primary ring-opacity-50;
    }
}

.form-label {
    @apply block text-sm font-medium text-gray-700;
}

// Group input fields
.input-group {
    @apply flex mt-1 shadow-sm;

    .input-group-text {
        @apply inline-flex items-center px-3 text-sm text-gray-500 bg-gray-50 border border-r-0 border-gray-200;
    }

    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='url'],
    input[type='date'],
    input[type='datetime-local'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='color'],
    input[type='file'],
    textarea,
    select {
        // Remove default shadow when in group (avoid double shadow)
        @apply shadow-none;
    }
}

// -----------------------------------------------------------------------------
// Select styling
// https://stackoverflow.com/a/54955847/4156752
// Fix select readonly still changes value
select[readonly]:-moz-read-only {
    /* For Firefox */
    @apply pointer-events-none;
}

select[readonly]:read-only {
    @apply pointer-events-none;
}

select[size] {
    @apply pr-3 bg-none;
}

// -----------------------------------------------------------------------------
// Color picker styling
// https://stackoverflow.com/a/11471224/4156752
// Fix Chrome adding unwanted space around color
input[type='color'] {
    @apply appearance-none;
}

input[type='color']::-webkit-color-swatch-wrapper {
    @apply p-0;
}

input[type='color']::-webkit-color-swatch {
    @apply border-none;
}

input[type='color'] {
    @apply p-1 h-8;
    max-width: 3rem;
}

// -----------------------------------------------------------------------------
// Range styling
// https://www.cssportal.com/style-input-range/
input[type='range'] {
    $thumb-color: theme('colors.primary');
    $thumb-color-disabled: theme('colors.gray.400');
    $thumb-height-width: 1.25rem;

    $track-color: theme('colors.gray.200');
    $track-height: 0.5rem;

    // Notes: Firefox adds gray bg when disabled, so make transparent
    @apply w-full bg-transparent appearance-none;
    height: calc(#{$thumb-height-width} + 1px); // Add all heights together

    // Track
    @mixin track {
        @apply w-full cursor-pointer;
        height: $track-height;
    }

    &::-webkit-slider-runnable-track {
        @include track;
        background: $track-color;
        border-radius: 8px;
    }

    &::-moz-range-track {
        @include track;
        background: $track-color;
        border-radius: 8px;
    }

    &::-ms-track {
        @include track;
        @apply bg-transparent border-transparent;
        color: transparent;
    }

    &::-ms-fill-lower {
        background: $track-color;
        border-radius: 16px;
    }

    &::-ms-fill-upper {
        background: $track-color;
        border-radius: 16px;
    }

    &:disabled {
        @mixin track-disabled {
            @apply cursor-default;
        }

        &::-webkit-slider-runnable-track {
            @include track-disabled;
        }

        &::-moz-range-track {
            @include track-disabled;
        }

        &::-ms-track {
            @include track-disabled;
        }
    }

    // Thumb
    @mixin thumb {
        @apply cursor-pointer;
        border: 1px solid $thumb-color;
        border-radius: 100%;
        height: $thumb-height-width;
        width: $thumb-height-width;
        background: $thumb-color;
    }

    &::-webkit-slider-thumb {
        @include thumb;
        @apply appearance-none -mt-1.5;
    }

    &::-moz-range-thumb {
        @include thumb;
    }

    &::-ms-thumb {
        @include thumb;
        @apply mt-px;
    }

    &:disabled {
        @mixin thumb-disabled {
            @apply cursor-default;
            background: $thumb-color-disabled;
            border: 1px solid $thumb-color-disabled;
        }

        &::-webkit-slider-thumb {
            @include thumb-disabled;
        }

        &::-moz-range-thumb {
            @include thumb-disabled;
        }

        &::-ms-thumb {
            @include thumb-disabled;
        }
    }

    // Focused
    &:focus {
        @apply outline-none;

        &::-webkit-slider-runnable-track {
            background: $track-color;
        }

        &::-ms-fill-lower {
            background: $track-color;
        }

        &::-ms-fill-upper {
            background: $track-color;
        }
    }
}

// -----------------------------------------------------------------------------
// Upload input

input[type='file'] {
    @apply p-0;

    @mixin file {
        @apply py-2 px-3 mr-2 bg-gray-200 rounded-none border-0 border-gray-200 border-solid transition-colors cursor-pointer text-dark;
        border-inline-end-width: 1px;

        &:hover {
            @apply bg-gray-300;
        }
    }

    &::-ms-browse {
        @include file;
    }

    &::-webkit-file-upload-button {
        @include file;
    }

    &::file-selector-button {
        @include file;
    }
}

// -----------------------------------------------------------------------------
// Checkbox & Radio

input[type='checkbox'],
input[type='radio'] {
    margin-top: -0.2em; // Properly center the box
    //vertical-align: top;
    background-color: theme('colors.white');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid theme('colors.gray.300');
    appearance: none;
    color-adjust: exact; // Keep themed appearance for print
    @apply shadow-sm;

    &:active {
        filter: brightness(90%); // Darken on click
    }

    &:focus {
        // Ring on tabbing
        @apply ring-2 ring-opacity-50 ring-offset-0 outline-none ring-primary-light;
    }

    &:checked {
        background-color: theme('colors.primary');
        background-size: contain;

        &:hover, &:focus {
            background-color: theme('colors.primary-dark');
        }
    }

    &:disabled,  &:disabled ~ label {
        pointer-events: none;
        filter: none;
        opacity: 0.5;
    }
}

input[type='radio'] {
    border-radius: 100%;
}

input[type='checkbox'] {
    border-radius: 0.25em;

    &:indeterminate {
        background-color: theme('colors.primary');

        &:hover, &:focus {
            background-color: theme('colors.primary-dark');
        }
    }

    &.switch {
        $switch-color-white: #ffffff;
        $switch-color-gray-300: #d1d5db;
        $switch-color-gray-400: #9ca3af;
        $switch-color-primary-light: #ef4444;

        border-radius: 0.5em;
        width: 2em;
        background-image: svg.svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$switch-color-gray-300}'/></svg>");
        background-position: left center;

        &:focus, &:hover {
            background-image: svg.svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$switch-color-primary-light}'/></svg>");
        }
        &:checked:focus, &:checked:hover,
        &:indeterminate:focus, &:indeterminate:hover {
            background-image: svg.svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$switch-color-gray-400}'/></svg>");
        }

        &:checked {
            background-position: right center;
            background-image: svg.svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$switch-color-white}'/></svg>");
        }

        &:indeterminate {
            background-position: center center;
            background-image: svg.svg-url("<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$switch-color-white}'/></svg>");
        }
    }
}
