pre.code {
    white-space: pre-wrap;

    &:before {
        counter-reset: listing;
    }
    code {
        counter-increment: listing;

        &:before {
            content: counter(listing) '. ';
            display: inline-block;
            width: 3em;
            padding-left: 0;
            margin-left: auto;
            text-align: right;
        }
    }
}
