@use 'sass:math';

// Default font style
html {
    font-size: 100%; // 16px
}

body {
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    @apply text-dark;
}

// Specific font style
$default-font-size: 1em;
$default-line-height: 1.65;

// https://type-scale.com/
$multiplier-minor-second: 1.067;
$multiplier-major-second: 1.125;
$multiplier-minor-third: 1.200;
$multiplier-major-third: 1.250;
$multiplier-perfect-fourth: 1.333;
$multiplier-augmented-fourth: 1.414;
$multiplier-perfect-fifth: 1.500;
$multiplier-golden-ratio: 1.618;

@function font-size($multiplier, $exponent) {
    @return math.pow($multiplier, $exponent) * $default-font-size;
}

@function line-height($multiplier, $exponent) {
    $result: $default-line-height;
    @for $_ from 1 through $exponent {
        $result: ($result - 1) * (2 - $multiplier) + 1;
    }
    @return $result;
}

@mixin font($exponent) {
    font-size: font-size($multiplier-minor-second, $exponent);
    line-height: line-height($multiplier-minor-second, $exponent);

    @screen md {
        font-size: font-size($multiplier-major-second, $exponent);
        line-height: line-height($multiplier-major-second, $exponent);
    }
    @screen lg {
        font-size: font-size($multiplier-minor-third, $exponent);
        line-height: line-height($multiplier-minor-third, $exponent);
    }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-weight: bold;
}

h1, .h1 {
    @include font(6);
}

h2, .h2 {
    @include font(5);
}

h3, .h3 {
    @include font(4);
}

h4, .h4 {
    @include font(3);
}

h5, .h5 {
    @include font(2);
}

h6, .h6 {
    @include font(1);
}

p {
    font-size: $default-font-size;
    line-height: $default-line-height;
}

small {
    font-size: 0.75em;
}

blockquote,
blockquote p,
q,
abbr,
address,
cite {
    font-style: italic;
    @include font(1);
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-weight: bold;
}

// Default text margin
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
p,
blockquote,
blockquote p,
q,
abbr,
address,
cite,
pre, code{
    --font-margin: 0.8em;
    margin-top: var(--font-margin);
    margin-bottom: var(--font-margin);
}

// Always add space before headings (except if a heading follows another heading)
*:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) + h1,
*:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) + h2,
*:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) + h3,
*:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) + h4,
*:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) + h5,
*:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) + h6 {
    margin-top: 2rem;

    @screen md {
        margin-top: 3rem;
    }
    @screen lg {
        margin-top: 4rem;
    }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
p,
blockquote,
blockquote p,
q,
abbr,
address,
cite {
    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

a {
    @apply text-primary;

    &:focus {
        @apply outline-none ring ring-opacity-50 ring-primary-light;
    }

    img {
        text-decoration: none;
    }

    svg {
    }
}
a svg,
button svg {
    height: 1.3em;
    transform: translateY(-0.05em);
}
