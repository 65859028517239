.content {
    ul {
        @apply list-disc;
    }

    ol {
        @apply list-decimal;
    }

    ul, ol {
        --font-margin: 0.8em;
        margin-top: var(--font-margin);
        margin-bottom: var(--font-margin);
        padding: revert;
    }
}
