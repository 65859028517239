.video-container {
    @apply relative;

    &.video-16-9,
    &.video-4-3 {
        @apply w-full max-w-full;
    }

    &.video-16-9 {
        padding-bottom: 56.25%;
    }

    &.video-4-3 {
        padding-bottom: 75%;
    }

    .video {
        @apply absolute top-0 left-0 w-full h-full border-none;
    }
}
